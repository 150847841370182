<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">我下达的行车任务</h1>
    </div>
    <a-row :gutter="16">
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="车辆总数"
            :value="statisticsData.allNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="执行中车辆"
            :value="statisticsData.goingNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="空闲中车辆"
            :value="statisticsData.freeNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="已完成任务"
            :value="statisticsData.recordCompleteNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="执行中任务"
            :value="statisticsData.recordDoingNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="4">
        <a-card>
          <a-statistic
            title="待执行任务"
            :value="statisticsData.recordNotNumber"
            :valueStyle="valueStyle"
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
        v-auth="auth_car.RECORD_CREATE_AND_UPDATE"
      >制定任务
      </a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="车辆名称">
              <a-select
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carInfo"
                placeholder="请选择"
                @change="search"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="执行人">
              <a-tree-select
                allowClear
                :treeData="userList"
                v-model="executeUserStr"
                @change="beforeSearch"
                placeholder="请选择执行人"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="任务状态">
              <a-radio-group @change="selectStatus" v-model="searchParams.status">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="NOT">待执行</a-radio-button>
                <!--<a-radio-button value="DOING">执行中</a-radio-button>-->
                <a-radio-button value="COMPLETED">已完成</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="制定时间">
              <a-range-picker @change="dateOnChange" v-model="dateSearch">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="rowClick"
        :rowKey="(record) => record.id"
        bordered
        :scroll="{ x: 1500 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="executeTime" slot-scope="text, record">
          <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === 'NOT'">待执行</a-tag>
          <a-tag v-if="record.status === 'DOING'" color="#87d068">执行中</a-tag>
          <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
        </template>
        <!-- <template slot="type" slot-scope="text, record">
           <span v-if="record.type==='GIVEN'">下达</span>
           <span v-if="record.type==='AUTO'">自主</span>
         </template>-->
        <template slot="action" slot-scope="text, record">
          <a
            @click="beforeHandleEdit(record.id)"
            v-if="record.status === 'NOT'"
            v-auth="auth_car.RECORD_CREATE_AND_UPDATE"
            @click.stop
          >编辑</a
          >
          <a-divider type="vertical" v-if="record.status === 'NOT'" v-auth="auth_car.RECORD_CREATE_AND_UPDATE"/>
          <a @click="handleDelete(record.id)" v-if="record.status === 'NOT'" v-auth="auth_car.RECORD_DELETE" @click.stop
          >删除</a
          >
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="制定任务" v-model="addModal" width="600px">
      <car-record-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></car-record-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改任务"
      :visible="editModal"
      width="600px"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <car-record-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></car-record-form>
    </a-modal>
    <a-modal :title="platNumber" :visible="detailDrawer" :width="1400" centered :footer="null" @cancel="detailClose">
      <car-record-detail ref="viewRef" :dbDevicesData="dbDevicesData"></car-record-detail>
    </a-modal>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { carRecordColumns } from './common/common'
  import CarRecordForm from './Form'
  import CarRecordDetail from '../Detail'
  import mapDevices from '../../../map/composite/mixins/map-devices'

  export default {
    name: 'carRecordList',
    mixins: [entityCRUDWithCopy, mapDevices],
    components: { PageLayout, CarRecordForm, CarRecordDetail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.car.carRecordApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          managerUser: this.$store.getters.currentUser.id,
          executeUser: undefined,
          carInfo: undefined,
          status: '',
          type: 'GIVEN',
          startTime: '',
          endTime: ''
        },
        initColumns: carRecordColumns(),
        orgTree: [],
        userList: [], //下发/制定人下拉数据
        carList: [],
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        statisticsData: {},
        dateSearch: null, //日期绑定数据
        platNumber: '', //车牌号
        executeUserStr: undefined
      }
    },
    created () {
      this.getOrgTree()
      this.loadUserList()
      this.loadCarInfoList()
      this.loadStatisticsData()
    },
    methods: {
      selectStatus () {
        this.search()
      },
      loadStatisticsData () {
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.statistics,
          data: {
            managerUser: this.$store.getters.currentUser.id
          },
          noTips: true,
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.platNumber = '车牌号：' + record.carInfo.platNumber
              this.handleView(record.id)
            }
          }
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      //日期选择
      dateOnChange (date, dateString) {
        this.searchParams.startTime = dateString[0]
        this.searchParams.endTime = dateString[1]
        this.search()
      },
      /*重置*/
      resetForm () {
        this.executeUserStr = undefined
        this.dateSearch = null
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
        Object.assign(this.searchParams, this.initParams)
        this.search()
      },
      // 若页面未刷新，需判断该任务是否可编辑
      beforeHandleEdit (id) {
        this.$http(this, {
          url: SERVICE_URLS.car.carRecordApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            if (data.body.status !== 'NOT') {
              this.$message.info('非待执行任务，不允许修改！')
              // this.search()
            } else {
              this.handleEdit(id)
            }
          }
        })
      },
      beforeSearch () {
        this.searchParams.executeUser = this.executeUserStr ? this.executeUserStr.split('_')[2] : undefined
        this.search()
      }
    }
  }
</script>

<style scoped></style>
